import { defineStore } from "pinia";
import type { FavoriteType as Favorite } from "@/types/FavoriteType";
import type { CollectionType as Collection } from "@/types/CollectionType";
import FavoriteService from "@/services/FavoriteService";

export type FavoritableType = "Listing";

export const useFavoriteStore = defineStore("favorites", {
  state: () => ({
    favorites: [] as Favorite[],
    filteredFavorites: [] as Favorite[],
    collections: [] as Collection[],
    currentCollection: null as Collection | null,
  }),
  getters: {
    findFavorite:
      (state) =>
      (id: string, type: FavoritableType): Favorite | undefined => {
        return state.favorites.find(
          (favorite) =>
            favorite.favoritableId === id && favorite.favoritableType === type
        );
      },
    isFavorited:
      (state) =>
      (id: string, type: FavoritableType): boolean => {
        return state.favorites.some(
          (favorite) =>
            favorite.favoritableId === id && favorite.favoritableType === type
        );
      },
  },
  actions: {
    filterFavoritesByIds(ids: string[]) {
      this.filteredFavorites = this.favorites.filter((listing) =>
        ids.includes(listing.id)
      );
    },
    async fetchFavorites() {
      this.favorites = await FavoriteService.fetchAll();
    },
    async createFavorite(id: string, type: FavoritableType) {
      const favorite = await FavoriteService.create(id, type);
      this.favorites.push(favorite);
    },
    async removeFavorite(id: string, type: FavoritableType) {
      const favorite = this.findFavorite(id, type);
      if (!favorite) {
        throw new Error("Favorite not found");
      }
      await FavoriteService.delete(favorite.id);
      this.favorites = this.favorites.filter((item) => item.id !== favorite.id);
    },

    async fetchCollections() {
      this.collections = await FavoriteService.fetchCollections();
    },
    async createCollection(name: string) {
      const collection = await FavoriteService.createCollection(name);
      this.collections.push(collection);
    },
    async fetchCurrentCollectionById(id: string) {
      this.currentCollection = await FavoriteService.fetchCollectionById(id);
    },
		async addFavoriteToCollection(favoriteId: string, collectionId: string) {
			await FavoriteService.addFavoriteToCollection(
				favoriteId,
				collectionId
			);
			this.fetchCollections()
		},
  },
});
