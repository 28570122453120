import { defineStore } from 'pinia'
import { User } from '@/types/User'


export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as User | null
  }),

  actions: {
    setUser(userData: User) {
      this.user = userData
    },

    clearUser() {
      this.user = null
    }
  },
})
