<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useAuth0 } from '@auth0/auth0-vue';

import UserAvatar from '@/components/UserAvatar.vue'

const { logout, user } = useAuth0();

const userNavigation = [
	{ name: 'Your Profile', click: '#' },
	// { name: 'Settings', click: '#' },
]

function logoutWithAuth0() {
	logout({
		logoutParams: {
			returnTo: window.location.origin
		}
	})
}

</script>

<template>
	<Menu as="div" class="relative ml-3" v-if="user">
		<div>
			<MenuButton
				class="relative flex max-w-xs items-center rounded-full bg-brand-primary-600 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-brand-primary-600">
				<span class="absolute -inset-1.5" />
				<span class="sr-only">Open user menu</span>
				<UserAvatar :url="user.picture"/>
			</MenuButton>
		</div>
		<transition enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95">
			<MenuItems
				class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
				<MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
				<a :href="item.href"
					:class="[active ? 'bg-gray-100' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a>
				</MenuItem>
				<MenuItem v-slot="{ active }">
					<a @click.prevent="logoutWithAuth0" :class="[active ? 'bg-gray-100' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700']">Sign Out</a>
					</MenuItem>
			</MenuItems>
		</transition>
	</Menu>

</template>