import { defineStore } from 'pinia'
import { Auth0User } from '@/types/Auth0'

export const useAuth0Store = defineStore('auth0', {
  state: () => ({
    user: null as Auth0User | null
  }),

  actions: {
    setUser(userData: Auth0User) {
      this.user = userData
    },

    clearUser() {
      this.user = null
    }
  },
})
