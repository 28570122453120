import { DefaultConfigOptions } from '@formkit/vue'
import { createMultiStepPlugin } from '@formkit/addons'
import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
// import { createAutoAnimatePlugin } from '@formkit/addons'
// import '@formkit/addons/css/multistep'

import formkitTheme from './formkit.theme'



const config: DefaultConfigOptions = {
  plugins: [createMultiStepPlugin()],
	icons: genesisIcons,
	config: {
		classes: generateClasses(formkitTheme)
	}
}


export default config