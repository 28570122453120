import { createApp } from 'vue'
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { plugin as formKitPlugin, defaultConfig } from '@formkit/vue'
import { register as registerSwiper } from "swiper/element/bundle";
import formkitConfig from '../formkit.config'
import { auth0 } from '@/composables/useAuth0';
import App from './App.vue'
import router from './router';
import "@fontsource/merriweather/300.css"
import "@fontsource/merriweather/400.css"
import "@fontsource/merriweather/700.css"
import "@fontsource/merriweather/900.css"
const pinia = createPinia()
/* Theme variables */
import './theme/variables.css';
registerSwiper();
export const app = createApp(App);

app
  .use(IonicVue)
	.use(pinia)
	.use(auth0)
  .use(router)
	.use(formKitPlugin, defaultConfig(formkitConfig))
	.use(autoAnimatePlugin)
	.mount("#app");
