import { createRouter, createWebHistory } from 'vue-router';
import { RouteRecordRaw } from 'vue-router';
import { createAuthGuard, useAuth0 } from "@auth0/auth0-vue";

const requireUser = createAuthGuard()
const checkForUser = async (to: any, _from: any, next: any) => {
  const { isAuthenticated, isLoading } = useAuth0();

  // Wait until the authentication loading is finished.
  // Necessary if your authentication status is retrieved asynchronously.
  if (isLoading.value) {
    await new Promise(resolve => {
      const checkLoading = setInterval(() => {
        if (!isLoading.value) {
          clearInterval(checkLoading);
          resolve(null);
        }
      }, 100);
    });
  }

  if (isAuthenticated.value && to.path !== '/dashboard') {
    next('/dashboard');
  } else if (!isAuthenticated.value && to.path !== '/') {
    // Optional: Redirect unauthenticated users away from protected routes
    next('/');
  } else {
    next(); // Continue with the routing if already going to the correct route
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/HomePage.vue"),
    name: "Home",
    beforeEnter: checkForUser,
  },
  {
    path: "/auth0/callback",
    component: () => import("@/views/Auth0Callback.vue"),
  },
  {
    path: "/listings",
    name: "ListingsPage",
    component: () => import("@/views/ListingsPage.vue"),
    children: [],
  },
  {
    path: "/listings/favorites",
    name: "FavoritedListingsPage",
    component: () => import("@/views/listings/FavoritedListingsPage.vue"),
    children: [],
  },
  {
    path: "/listings/collections",
    name: "FavoriteCollectionsPage",
    component: () => import("@/views/listings/FavoriteCollectionsPage.vue"),
    children: [],
  },
  {
    path: "/listings/collections/:id",
    name: "FavoriteCollectionPage",
    component: () => import("@/views/listings/FavoriteCollectionPage.vue"),
    children: [],
  },
  {
    path: "/listings/:id",
    name: "ListingPage",
    component: () => import("@/views/ListingPage.vue"),
    beforeEnter: requireUser,
  },
  {
    path: "/my-practice",
    name: "MyPracticePage",
    component: () => import("@/views/practices/MyPracticePage.vue"),
    beforeEnter: requireUser,
    children: [],
  },

  {
    path: "/practices/new",
    name: "NewPracticePage",
    component: () => import("@/views/practices/NewPracticePage.vue"),
    beforeEnter: requireUser,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/DashboardPage.vue"),
    beforeEnter: requireUser,
  },
  {
    path: "/login",
    name: "Login",
    redirect: "/dashboard",
  },
  {
    path: "/:pathMatch(.*)*", // Vue Router 4 syntax for catch-all route
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
