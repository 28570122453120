import api from "./ApiService";
import { UserPayload } from "@/types/User";

const UserService = {
	getByEmail: async (email: string) => {
		return api.get(`/v1/users/${email}`);
	},
	update: async (payload: UserPayload) => {
		return api.put(`/v1/users/${payload.id}`, {user: payload});
	}
}

export default UserService;