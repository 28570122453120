import ApiService from "./ApiService";
import {FavoriteType as Favorite} from "@/types/FavoriteType";
import type {CollectionType as Collection} from "@/types/CollectionType";
import AddFavoriteToCollectionButton from "@/components/favorites/AddFavoriteToCollectionButton.vue";

const FAVORITES_BASE_URL = "/v1/favorites";
const FAVORITE_COLLECTIONS_BASE_URL = "/v1/favorite_collections";

export const FavoriteService = {
  fetchAll: async (): Promise<Favorite[]> => {
    const response = await ApiService.get(FAVORITES_BASE_URL);
		return response.data as Favorite[];
  },
	create: async (favoritableId: string, favoritableType: string): Promise<Favorite> => {
		const response = await ApiService.post(FAVORITES_BASE_URL, { favorite: {favoritableId, favoritableType} });
		return response.data as Favorite;
	},
	delete: async (id: string) => {
		return ApiService.delete(`${FAVORITES_BASE_URL}/${id}`);
	},
	fetchCollections: async (): Promise<Collection[]> => {
		const response = await ApiService.get(FAVORITE_COLLECTIONS_BASE_URL);
		return response.data as Collection[];
	},
	fetchCollectionById: async (id: string): Promise<Collection> => {
		const response = await ApiService.get(`${FAVORITE_COLLECTIONS_BASE_URL}/${id}`);
		return response.data as Collection;
	},
	createCollection: async (name: string): Promise<Collection> => {
		const response = await ApiService.post(FAVORITE_COLLECTIONS_BASE_URL, {
      favorite_collection: { name },
    });
		return response.data as Collection;
	},
	addFavoriteToCollection: async (favoriteId: string, collectionId: string) => {
		return ApiService.post(`${FAVORITE_COLLECTIONS_BASE_URL}/${collectionId}/favorites`, {
			favorite_id: favoriteId,
		});
	}
}

export default FavoriteService;