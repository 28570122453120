import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { auth0 } from '@/composables/useAuth0';

const { getAccessTokenSilently } = auth0;

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Client": Capacitor.isNativePlatform() ? "mobile" : "web",
  },
});

export const PublicApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Client": Capacitor.isNativePlatform() ? "mobile" : "web",
  },
});

// Request interceptor to add the auth token header to requests
ApiService.interceptors.request.use(
  async (config) => {
    const token = await getAccessTokenSilently();
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiService;